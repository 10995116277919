import React, { useState } from 'react';
import * as firebase from 'firebase';
import logo from './logo.svg';
import Calendar from 'react-calendar';
import { differenceInCalendarDays } from 'date-fns';
import AwesomeSlider from 'react-awesome-slider';
import DatePicker from 'react-datepicker';
import firebaseinit from './firebase-credentials';
import moment from 'moment';
import Slider from 'rc-slider';
import Modal from 'tg-modal';
import ReactModal from 'react-modal';
import {Helmet} from "react-helmet";
import {useSpring, animated, useTransition} from 'react-spring';
import NumericInput from 'react-numeric-input';
import { addDays, addMonths, subDays, eachDay } from "date-fns";
import PublicIcon from '@material-ui/icons/Public';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import WebIcon from '@material-ui/icons/Web';

import './App.css';
import 'react-calendar/dist/Calendar.css';
import 'rc-slider/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';

const disabledDates = [new Date(), new Date(2020, 9, 1, 0, 0, 0), new Date(2020, 9, 2, 0, 0, 0),new Date(2020, 9, 3, 0, 0, 0), new Date(2020, 9, 4, 0, 0, 0), new Date(2020, 9, 5, 0, 0, 0), new Date(2020, 9, 6, 0, 0, 0), new Date(2020, 9, 7, 0, 0, 0), new Date(2020, 9, 8, 0, 0, 0), new Date(2020, 9, 9, 0, 0, 0), new Date(2020, 9, 10, 0, 0, 0), new Date(2020, 9, 11, 0, 0, 0), new Date(2020, 9, 12, 0, 0, 0), new Date(2020, 9, 13, 0, 0, 0), new Date(2020, 9, 14, 0, 0, 0), new Date(2020, 9, 15, 0, 0, 0), new Date(2020, 9, 16, 0, 0, 0), new Date(2020, 9, 17, 0, 0, 0), new Date(2020, 9, 18, 0, 0, 0), new Date(2020, 9, 19, 0, 0, 0), new Date(2020, 9, 20, 0, 0, 0), new Date(2020, 9, 21, 0, 0, 0), new Date(2020, 9, 22, 0, 0, 0), new Date(2020, 9, 23, 0, 0, 0), new Date(2020, 9, 24, 0, 0, 0), new Date(2020, 9, 25, 0, 0, 0), new Date(2020, 9, 26, 0, 0, 0), new Date(2020, 9, 27, 0, 0, 0), new Date(2020, 9, 28, 0, 0, 0), new Date(2020, 9, 29, 0, 0, 0), new Date(2020, 9, 31, 0, 0, 0)];
const database = firebaseinit.database();

function isSameDay(a, b) {
  return differenceInCalendarDays(a, b) === 0;
}

function tileDisabled({ date, view }) {
  // Disable tiles in month view only
  if (view === 'month') {
    // Check if a date React-Calendar wants to check is on the list of disabled dates
    return disabledDates.find(dDate => isSameDay(dDate, date));
  }
}

function App() {
  const props = useSpring({opacity: 1, from: {opacity: 0}});
  const [on, toggle] = useState(false);
   const [value, setValue] = useState(new Date());
   const [showbookingdate, setshowbookingdate] = useState(false);
   const [showdeliverydate, setshowdeliverydate] = useState(false);
   const [weight, setweight] = useState('0');
   const [bookingdate, setbookingdate] = useState(new Date());
   const [deliverydate, setdeliverydate] = useState(new Date());
   const [bookingbutton, setbookingbutton] = useState('Booking Date');
   const [deliverybutton, setdeliverybutton] = useState('Delivery Date');
   const [shipping, setshipping] = useState('0');

   const [startDate, setStartDate] = useState('');

   const [isOpen, setOpen] = useState(false);

   const [bname, setbname] = useState('');
   const [person, setperson] = useState('');
   const [bemail, setemail] = useState('');
   const [bnum, setnum] = useState('');
   const [ack, setack] = useState(false);

   const [route, setRoute] = useState('NA');

   const [showfees, setshowfees] = useState(false);

   const [fee, setfee] = useState('----');


const transition = useTransition(on, null, {
from: { opacity: 0 },
enter: { opacity: 1 },
leave: { opacity: 0 }
});

function toggleModal() {
  setOpen(!isOpen);
}

function submitCP() {
  const name = bname;
  const pername = person;
  const email = bemail;
  const number = bnum;
  console.log(name);

  const cp = {
    businessname : name,
    businessperson : pername,
    businessnum : number,
    businessemail : email,
    type: 'merchant'
  };

  database.ref('beckbagpartner').child(number).update(cp);

  const apiurl = 'https://newbeckfriends.herokuapp.com/api/bbmail';
    const postBody = {
        url: email,
        limit: 10
    };
    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(postBody)
    };
    fetch(apiurl, requestMetadata)
    .then(response => response.json())
    .then(data => {
    })
    .catch(e => {
      
    });  

  setack(true);


}  

  return (
    <div className="App" style={{ background: 'white' }}>
    <Helmet>
                <meta charSet="utf-8" />
                <title>BeckBags Merchants | Send Anything Anwyhere</title>
                <link rel="canonical" href="http://beckbags.tech/merchants" />
            </Helmet>
      <section className="container-pic-merchants newbeckbagback merchants">
        <div style={{ margin: '10px' }}>
        <img className="beckbaglogo" src={'https://firebasestorage.googleapis.com/v0/b/beckfriends-2-a4131.appspot.com/o/beckbag_logo_solid_modified_small.png?alt=media&token=fa4e1225-d665-484f-9db1-ac6f369da512'} alt="profilePic" style={{ width: '12%', float: 'left' }} />
        <button onClick={toggleModal} className="btn btn-primary inlineblock buybtn_1 buybtn_2" style={{ padding: '10px 22px', fontWeight: '500', backgroundColor: '#007272', color: 'white', border: '2px solid #007272', outline: 'none', letterSpacing: 1, borderRadius: '6px', float: 'right', fontSize: '1em' }}>Request Access</button>
        </div>
        <h2 className="mobilemargintop" style={{ fontSize: '2.5em', color: 'rgb(0, 114, 114)', fontFamily: 'WalsheimBold' }}> Send Anywhere</h2>
        <h2>Whenever you need in a single tap</h2>
        <div className="row container-pic" style={{ marginTop: '1%', marginRight: '0px', marginLeft: '0px' }}>
          <div className="col-md-4 columnmobile" style={{ padding: '1%', background: 'white', marginLeft: '1%' }}>
            <h1 className="mobilemerchanttext mobilemargintop" style={{ fontSize: '32px', textAlign: 'left', marginLeft: '15px', color: 'black', fontWeight: '600', marginBottom: '1em' }}>Ship fast and inexpensively</h1>
            <h1 className="mobilemerchanttext" style={{ fontSize: '22px', textAlign: 'left', marginLeft: '15px', color: 'black', marginBottom: '20px' }}>Experience hassle-free express shipping to any part of the world at economic prices.</h1>
            <h1 className="mobilemerchanttext" style={{ fontSize: '22px', textAlign: 'left', marginLeft: '15px', color: 'black' }}>Try shipping with us to see why couriers are a thing of the past.</h1>
            <button onClick={toggleModal} className="btn btn-primary inlineblock buybtn_1 buybtn_2 mobilemarginbottom" style={{ backgroundColor: 'white', color: 'rgb(0, 114, 114)', border: 'none', outline: 'none', letterSpacing: 2, fontWeight: 600, borderRadius: '5px', textTransform: 'uppercase', marginLeft: '15px', padding: '1em', float: 'left', fontSize: '1.1em', marginTop: '20px' }}>Request Access<i className="fa fa-long-arrow-right icon-left" aria-hidden="true" style={{ fontSize: '1.25em', marginLeft: '0.7em', verticalAlign: '-2px' }} /></button>
          </div>
          
        </div>
      </section>
      
        <ReactModal
           className="ModalReq"
           isOpen={isOpen}
           contentLabel="Modal #1 Global Style Override Example"
           onRequestClose={toggleModal}
        >
          { !ack && <h4 className="headertext2 header3" style={{ width: '100%', fontWeight: 800, color: '#000', marginBottom: '2em', fontSize: '1.4em', textAlign: 'center' }}>Please share your details here</h4>}
          <button onClick={toggleModal} style={{ position: 'absolute', top: '10px', right: '10px', border: '0px', background: 'white' }}>❌</button>
          { !ack && <input className="another buydiv mobilepartner" type="text" placeholder="Business Name" value={bname} onChange={setbname} onChange={event => setbname(event.target.value)} style={{ width: '100%', border: '1px solid Black', borderWidth: '0px', background: '#ffffff', fontWeight: 600, marginBottom: '1em', borderBottomWidth: '1px', padding: '10px' }} />}
              { !ack && <input className="another buydiv mobilepartner" type="text" placeholder="Contact Person" value={person} onChange={event => setperson(event.target.value)} style={{ width: '100%', border: '1px solid Black', borderWidth: '0px', background: '#ffffff', fontWeight: 600, marginBottom: '1em', borderBottomWidth: '1px', padding: '10px' }} />}
              { !ack && <input className="another buydiv mobilepartner" type="text" placeholder="Contact Email" value={bemail} onChange={event => setemail(event.target.value)} style={{ width: '100%', border: '1px solid Black', borderWidth: '0px', background: '#ffffff', fontWeight: 600, marginBottom: '1em', borderBottomWidth: '1px', padding: '10px' }} />}
              { !ack && <input className="another buydiv mobilepartner" type="text" placeholder="Contact Number" value={bnum} onChange={event => setnum(event.target.value)} style={{ width: '100%', border: '1px solid Black', borderWidth: '0px', background: '#ffffff', fontWeight: 600, marginBottom: '1em', borderBottomWidth: '1px', padding: '10px' }} />}
              { !ack && <button onClick={submitCP} className="btn btn-primary inlineblock buybtn_1 buybtn_2  mobilepartner" style={{ backgroundColor: 'rgb(33, 108, 85)', border: 'none', outline: 'none', letterSpacing: 1, fontWeight: 600, width: '85%', borderRadius: '5px', textTransform: 'uppercase', width: '100%', marginTop: '15px' }}>Submit</button>}
              { ack && <h4 className="headertext2 header3" style={{ width: '100%', fontWeight: 800, color: 'green', marginBottom: '1em', fontSize: '1.4em', textAlign: 'center' }}>Submitted Successfully ✅</h4>}
              { ack && <h4 className="headertext2 header3" style={{ width: '100%', fontWeight: 800, color: 'green', fontSize: '1.4em', textAlign: 'center' }}>Our team will be in touch shortly</h4>}
        </ReactModal>
        <div style={{ backgroundColor: '#fff', margin: '0 auto', padding: '1em 1em 1em', marginBottom: '15px' }}>
          <div className="row">
          <h1 style={{ marginBottom: '1em', marginTop: '1em', fontWeight: 600 }}>Why use BeckBags ?</h1>
            <div className="why row" style={{ padding: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img className="beckbaglogonew" src={'https://firebasestorage.googleapis.com/v0/b/project-1348531828359008657.appspot.com/o/dashboard.png?alt=media&token=4e449fc4-3f36-4e26-be96-1a2eaeb4da3b'} alt="Traveler" style={{ width: '60%', marginTop: '10px', marginLeft: '5px' }} />
            </div>
            <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <p style={{ fontWeight: '100', marginTop: '1em', fontSize: '1.5em', textAlign: 'center' }}>SAAS tool that provides an order management platform to track and manage all your orders or integrates with your existing platform.</p>
            </div>
            </div>
            <div className="why row" style={{ padding: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <p style={{ fontWeight: '100', marginTop: '1em', fontSize: '1.5em', textAlign: 'center' }}>Dynamic recommended pricing to provide best possible price and service. The tool recommends a daily price which changes real time on current supply/demand scenario.</p>
            </div>
            <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img className="beckbaglogonew" src={'https://firebasestorage.googleapis.com/v0/b/project-1348531828359008657.appspot.com/o/merchants_price.png?alt=media&token=693b02c1-a301-4f52-b93d-2492e27139ad'} alt="Traveler" style={{ width: '60%', marginTop: '10px', marginLeft: '5px' }} />
            </div>
            </div>
            <div className="why row" style={{ padding: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img className="beckbaglogonew" src={'https://firebasestorage.googleapis.com/v0/b/project-1348531828359008657.appspot.com/o/merchants.png?alt=media&token=3b285ac5-5ec3-4623-80c0-8639a4386a58'} alt="Traveler" style={{ width: '60%', marginTop: '10px', marginLeft: '5px' }} />
            </div>
            <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <p style={{ fontWeight: '100', marginTop: '1em', fontSize: '1.5em', textAlign: 'center' }}>BeckBags is an alternative shipping channel with built in simplicity for booking and tracking.</p>
            </div>
            </div>
          </div>
        </div>
<div style={{ backgroundColor: '#ffffff', margin: '0 auto', padding: '1em 1em 1em', marginBottom: '15px' }}>
        <div style={{ backgroundColor: '#ffffff', width: '100%' }}>
          <div className="row" style={{ margin: '0 auto' }}>
            <h1 className="headertextdivs" style={{ width: '100%', fontWeight: 600, marginBottom: '1.2em', color: 'black' }}>It’s easy to get started</h1>
            <div className="row" style={{ paddingTop: '1em', margin: 0 }}>
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <div className="col-md-4 col-xs-12 margin-left-resp" style={{ paddingLeft: 0 }}>
                    <h1 className="headertextdivs" style={{ width: '100%', fontWeight: 500, fontSize: '2em', marginBottom: '1em' }}>Sign Up Online</h1>
                    <h1 className="headertextsubs" style={{ color: '#57585a', width: '100%', fontSize: '1.1em', fontWeight: 300 }}>Share your business and contact details with us and our representative will get in touch</h1>
                  </div>
                  <div className="col-md-4 col-xs-12" style={{ padding: '0 0.5em' }}>
                    <h1 className="headertextdivs mobilemargintop" style={{ width: '100%', fontWeight: 500, fontSize: '2em', marginBottom: '1em' }}>Share your documents</h1>
                    <h1 className="headertextsubs" style={{ color: '#57585a', width: '100%', fontSize: '1.1em', fontWeight: 300 }}>Share your Registration, business proof and the other necessary information once in order to be approved to start shipping</h1>
                  </div>
                  <div className="col-md-4 col-xs-12 margin-top-resp" style={{ paddingLeft: 0 }}>
                    <h1 className="headertextdivs mobilemargintop" style={{ width: '100%', fontWeight: 500, fontSize: '2em', marginBottom: '1em' }}>Get go with shipping</h1>
                    <h1 className="headertextsubs" style={{ color: '#57585a', width: '100%', fontSize: '1.1em', fontWeight: 300 }}>We’ll provide the dashboard to manage all of your orders and to book shipments</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>

  );
}

export default App;
